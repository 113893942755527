<template>
	<div>
		<TripHeader />
		<div class="outer-wrapper">

			<div>
				<Loader :loading="trip.loading" :error="trip.error" @refresh="refresh"/>
			</div>
			<div v-if="!trip.loading && !trip.error">

				<div class="inner-wrapper">
					<div class="passengers">
						<div class="heading">
							Who will be travelling? <img class="edit-section" @click="manageTravellers()" src="@/assets/travellers/edit-travellers.png" alt="Edit Passengers Icon" />
						</div>
						<div class="pax-outer" v-for="(pax, index) in trip.tripTravellers" :key="index"> <!-- each of these becomes a circular pax icon thing. For now, put generic icon instead of profile pic -->
							<TravellerIcon
								:name="pax.data.firstName"
								:lastName="pax.data.lastName"
								@click="editPax(pax.traveller_id)"
							/>
						</div>
						<div class="pax-outer">
							<AddTravellerIcon @click="manageTravellers()"/>
						</div>
					</div>
				</div>

				<div class="inner-wrapper" v-if="Object.keys(trip.tripItems).length > 0">
					<div class="notifications">
						<div v-for="tripItem in trip.tripItems" :key="tripItem.id">
							<div class="notifications-inner green" v-if="tripItem.bookingStatus.code == 'booked'">
								{{ tripItem.name }} / <b>Booked</b>
							</div>
							<!-- Don't show alerts if the trip item is booked? (v-else) -->
							<div class="notifications-inner" v-if="tripItem.alerts.all.length > 0" :class="tripItem.alerts.all[0].alert_code">
								{{ tripItem.name }} / <b>{{ tripItem.alerts.all[0].main_message }}</b>
							</div>
						</div>
					</div>

					<div class="add-more">
						<div class="text">Add something else?</div>
						<div class="add-more-container" >
							<div class="item-wrapper" @click="flightSearch">
								<div class="item">
									<img src="@/assets/trip/flights.png" alt="plane" />
								</div>
							</div>
							<div class="item-wrapper" @click="tourSearch">
								<div class="item">
									<img src="@/assets/trip/tours.png" alt="flag"/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="inner-wrapper" v-if="Object.keys(trip.tripItems).length == 0">
					<div class="select-search">
						<SearchTypeSelect
							@click.native="selectTripForSearch"
							:tripID="trip.tripID"
						/>
					</div>
				</div>

				<!-- IF empty (new?) trip -->
				<div class="inner-wrapper" v-if="Object.keys(trip.tripItems).length > 0">
					<div class="flights">
						<div class="heading">
							Booking Details
						</div>
						<span v-for="(tripItem, index) in trip.tripItems" :key="index" >
							<TripItem
								:id="tripItem.id"
								:tripID="trip.tripID"
								:type="tripItem.type"
								:name="tripItem.name"
								:price="tripItem.price"
								:date="tripItem.date"
								:travellers="tripItem.travellers"
								:bookingStatus="tripItem.bookingStatus"
								:alert="tripItem.alerts.all.length > 0 ? tripItem.alerts.all[0] : false"
								:data="tripItem.data"

								class="item"
							/>
						</span>
					</div>

					<div class="carbon-section" v-if="tripItemsCategorized.length > 0 && tripItemsCategorized.bookable.totalCo2.valid && tripItemsCategorized.bookableWithIssues.totalCo2.valid">
						<div class="carbon" :style="{'background-image': 'url(' + getImage(carbon.img) + ')'}">
							<span>
								Your carbon footprint for the unbooked items is:
							</span>
							<span class="total">
								{{ tripItemsCategorized.bookable.totalCo2.amount + tripItemsCategorized.bookableWithIssues.totalCo2.amount }}kg CO<span class="unit">2</span>
							</span>
						</div>
						<!-- <div class="points">
							You could earn <span>x points</span> by booking these items!
							Off set your Carbon Footprint for <span>£XX.XX</span>
						</div> -->
					</div>
				</div>

				<!-- <div class="projects" v-if="Object.keys(trip.tripItems).length > 0">
					<div class="slider">
						<ProjectCardSlider v-bind:slider_title="sliders.project_slider_title" />
					</div>
					<div class="your-score">
						<img src="@/assets/trip/badge-blue.png" alt="blue badge" class="badge"/>
						Your Score
						<div class="details">
							Your score is based on how green your travel providers are.
						</div>
						<div class="value">
							XYZ
						</div>
						You're <span>Amazing!</span>
						<img src="@/assets/clapping-hands-emoji.png" alt="clapping hands emoji" class="emoji" />
						<span class="details">
							How can I improve my score?
							<span class="i">i</span>
						</span>
					</div>
				</div> -->

				<TripItemsSummary
					:tripID="trip.tripID"
					:groupedTripItems="{
						'Bookable': {
							items: tripItemsCategorized.bookable.items.concat(tripItemsCategorized.bookableWithIssues.items),
							totalPrice: tripItemsCategorized.bookable.totalPrice + tripItemsCategorized.bookableWithIssues.totalPrice,
							heading: 'Bookable Item Summary'
						},
						'Booked': {
							items: tripItemsCategorized.booked.items.concat(tripItemsCategorized.bookedWithIssues.items),
							totalPrice: tripItemsCategorized.booked.totalPrice,
							heading: 'Booked Item Summary'
						},
					}"
					:showBookButton="true"
					@book="goToPayment()"
				/>

				<div class="inner-wrapper bottom-section" v-if="Object.keys(trip.tripItems).length > 0">
					<div class="not_booked_wrapper">
						<div class="remove-item" v-if="trip.bookingValidation.canDeleteTrip" @click="deleteTrip()">Delete Trip</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
	.outer-wrapper {
		position: relative;
		/* min-height: 660px; */
		/* padding-bottom: 60px; */
	}
	.warning {
		background-repeat: no-repeat;
		height: 46px;
		padding-left: 46px;
		margin: 10px 0px 23px 0px;
		font-size: 12px;
	}
	.add-more {
		margin: 42px 0px 20px 0px;
	}
	.pax-outer {
		display: inline-block;
		vertical-align: top;
		margin-bottom: 5px;
	}
	.passengers {
		margin: 20px 0px;
	}
	.passengers .edit-section {
		width: 28px
	}
	.heading {
		color: #118AB2;
	}
	.edit-section {
		float: right;
		color: #118AB2;
		cursor: pointer;
	}
	.flight-selection:hover {
		cursor: pointer;
		color: pink;
	}

	.flights {
	}
	.flights .heading {
		color: #1D1D1D;
		font-weight: 600;
	}
	.flights .item {
		min-width: 290px;
		max-width: 375px;
		margin-bottom: 25px;
	}

	.flight-card {
		margin-bottom: 20px;
	}
	.projects {
		text-align: center;
		font-size: 24px;
		font-weight: 700;
		margin-bottom: 40px;
	}
	.projects .slider {
		margin: 16px 0px 34px 0px;
	}
	.projects .your-score {
		font-size: 34px;
		color: #000000;
	}
	.projects .your-score img.badge {
		display: block;
		margin: 0 auto;
	}
	.projects .your-score .details {
		position: relative;
		font-size: 14px;
		font-weight: normal;
		width: 230px;
		margin: 15px auto;
		margin-bottom: 30px;
	}
	.projects .your-score .value {
		font-size: 64px;
		color: #50555C;
		margin: 15px auto;
		height: 120px;
		line-height: 120px;
		width: 120px;
		border: 8px solid #60D2A3;
		border-radius: 50%;
	}
	.projects .your-score img.emoji {
		display: block;
		margin: 22px auto;
	}
	.projects .i {
		position: absolute;
		top: 0px;
		border: 1px solid #008BB5;
		border-radius: 50%;
		display: inline-block;
		width: 8px;
		height: 8px;
		margin-left: 2px;
		font-size: 8px;
		font-weight: 800;
		line-height: 8px;
		color: #008BB5;
	}
	.projects .i:hover {
		cursor: pointer;
	}

	table.confirmation {
		margin: 0 auto;
		font-size: 14px;
		width: 100%;
		margin-bottom:20px;
	}
	table.confirmation td {
		line-height: 28px;
	}
	table.confirmation .value {
		text-align: right;
		font-weight: 600;
		color: #000000;
	}
	table.confirmation .value span {
		font-size: 9px;
	}
	.bottom-section {
		margin-top: 30px;
	}
	.bottom-section .not_booked_wrapper {
		text-align: center;
	}
	.bottom-section .remove-item {
		font-weight: 400;
		font-size: 12px;
		color: #50555C;

		background-image: url("../../assets/trip/remove-icon.png");
		background-repeat: no-repeat;
		background-size: 16px 16px;
		background-position: right;

		display: inline-block;
		padding-right: 25px;
		line-height: 16px;
		margin: 0 auto;

		cursor: pointer;
	}
</style>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Menu from '@/components/Menu.vue'
import TopLeftIcon from '@/components/TopLeftIcon.vue'
import router from '@/router';
import helpers from '@/helpers/helpers.js';
import TripItem from "@/components/trips/TripItem.vue";
import ProjectCardSlider from '@/components/sliders/ProjectCardSlider.vue';
import Loader from '@/components/Loader.vue';
import TravellerIcon from '@/components/travellers/TravellerIcon.vue';
import AddTravellerIcon from '@/components/travellers/AddTravellerIcon.vue';
import SearchTypeSelect from "@/components/SearchTypeSelect.vue";
import TripHeader from "@/components/trips/TripHeader.vue";
import TripItemsSummary from "@/components/trips/TripItemsSummary.vue";

export default {
	data() {
		return {
			sliders: {
				project_slider_title: "Choose the projects to support",
			},
			trip_bg: {
				img: "trip/barcelona",
			},
			exclamation: {
				img: "trip/exclamation-red",
			},
			carbon: {
				img: "flights-details/carbon-forest-bg"
			},
			plant_trees: {
				img: "trip/plant-trees",
			},
			trip_id: this.$route.params.trip_id ? this.$route.params.trip_id : null,

			passengers: [],

			error: "",
			passengersLoading: false,
			passengersError: "",

			// showExpiryPopup: true,
		}
	},
	computed: {
		...mapState({
			trip: state => state.trip,
		}),
		...mapGetters({
			tripBookingValidity: 'trip/tripBookingValidity',
			tripItemsCategorized: 'trip/tripItemsCategorized',
		})
	},
	async created () {
		// this.$emit('update:topLayoutSettings', {
		// 	hide: true,
		// });

		this.refresh()
	},
	methods: {
		formatDate: (date) => helpers.formatDate(date),
		getImage: (img) => helpers.getImage(img),
		...mapActions({
			initTrip: "trip/initTrip",
			setSelectedTrip: "basket/setSelectedTrip",
			setQty: "flightsSearch/setQty",
			setDestination: "flightsSearch/setDestination",
			alertSuccess: "alert/success",
			alertError: "alert/error",
			alertInfo: "alert/info",
			alertWarning: "alert/warning",
		}),
		viewFlightDetails(tripItemID) {
			router.push({
				name: "Saved Flights Details",
				params: {
					trip_id: this.trip_id,
					trip_item_id: tripItemID
				},
			});
		},
		viewTourDetails(tripItemID) {
			router.push({
				name: "Saved Tour Details",
				params: {
					trip_id: this.trip_id,
					trip_item_id: tripItemID
				},
			});
		},
		manageTravellers() {
			router.push({
				name: "Manage Trip Travellers",
				params: {
					trip_id: this.trip_id,
				},
			});
		},
		editPax(traveller_id) {
			router.push({
				name: "Edit Traveller",
				params: {
					trip_id: this.trip_id,
					traveller_id: traveller_id,
				},
				query: {
					redirect: window.location.pathname
				}
			});
		},
		addPax() {
			router.push({
				name: "Select Passenger",
				params: {
					trip_id: this.trip_id,
				},
			})
		},
		goToPayment() {
			if(this.tripBookingValidity.valid) {
				router.push({
					name: "Review",
					params: {
						trip_id: this.trip_id,
					}
				})
			} else {
				this.alertError(this.tripBookingValidity.error);
			}
		},
		deleteTrip() {
			if(this.trip.bookingValidation.canDeleteTrip) {
				router.push({
					name: 'Delete Trip',
					params: {
						trip_id: this.trip_id,
					}
				})
			}
		},
		selectTripForSearch() {
			this.setQty({ adults: this.trip.totalTripTravellers.Adult, children: this.trip.totalTripTravellers.Child, infants: this.trip.totalTripTravellers.Infant });
		},
		flightSearch() {
			// // Fill the destination based on other trip items.
			// Convert the tripitems into an array of key-value pairs
			const entries = Object.entries(this.trip.tripItems);

			// Sort tripItems by date
			const sortedEntries = entries.sort(([keyA, valueA], [keyB, valueB]) => {
				return new Date(valueA.date) - new Date(valueB.date);
			});

			// loop through the sorted entries, and find the first tour
			for(let i = 0; i < sortedEntries.length; i++) {
				if(sortedEntries[i][1].type == 'tour') {
					this.setDestination({ code: sortedEntries[i][1].data.nearestAirport.start, location_type: 'airport' }); // Set the destination based on the nearest airport to this tour
					break;
				}
			}

			this.selectTripForSearch();

			router.push({
				name: "Flights Locations",
				params: {
					trip_id: this.trip_id,
				},
			})
		},
		tourSearch() {
			this.selectTripForSearch();

			router.push({
				name: "Tours Keyword",
				params: {
					trip_id: this.trip_id,
				},
			})
		},
		async refresh() {
			this.$emit('update:topLayoutSettings', {
				left: {
					type: 'back',
					route: { name: 'My Trips' },
				},
				right: {
					type: 'none',
				},
				overlayContent: false,
			});

			await this.initTrip({ tripID: this.trip_id });

			// Update the color of the arrow now that the trip has been loaded and the header is shown
			this.$emit('update:topLayoutSettings', {
				left: {
					type: 'back',
					color: 'white',
					route: { name: 'My Trips' },
				},
				right: {
					type: 'none',
				},
				overlayContent: true,
			});
		}
	},
	components: {
		Menu,
		TopLeftIcon,
		ProjectCardSlider,
		Loader,
		TravellerIcon,
		AddTravellerIcon,
		SearchTypeSelect,
		TripHeader,
		TripItem,
		TripItemsSummary,
	}
};
</script>