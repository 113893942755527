<template>
	<div class="container">
		<div v-if="!trip.loading && !trip.error" class="trip-image" ref="tripImage" :style="[ this.headerImage ? {'background-image': `url(${this.headerImage})`, 'background-size': 'cover' } : {}]">
			<div class="inner">
				<div id="top-nav" class="outer-wrapper">

					<div class="image-upload" v-if="editTripHeader || !trip.tripImage" @click="editHeader()">
						<label for="image-select">
							<img class="upload-icon" src="@/assets/trip/upload-image.png"/>
							<div class="text" ref="addImageText">
								<span v-if="!trip.tripImage">Add Image</span>
								<span v-else>Change Image</span>
							</div>
						</label>

						<input type="file" id="image-select" @change="onImageSelected">
					</div>

					<div class="trip-label" @click="editHeader()">

						<div class="heading" v-if="!editTripHeader && !headerIsLoading">
							<span class="trip-name">{{ trip.tripName }}</span>
							<img src="@/assets/trip/edit.png" alt="Edit Button" class="edit" />
						</div>

						<div class="heading" v-if="editTripHeader">
							<span>Edit the trip name</span>
							<input ref="editNameInput" class="trip-name" v-model="trip.tripName" maxlength="35">
							<img class="save-tick" @click="saveTripHeader" src="@/assets/trip/save-tick.png" />
						</div>
						<div class="date">{{ tripDates }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<style scoped>
	.back-icon {
		width: 20px;
		height: initial;
	}
	.date {
		font-size: 20px;
		font-weight: 700;
		margin: 32px 0px;
		color: #118AB2;
	}
	.image-upload {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		text-align: center;

		cursor: pointer;
		max-width: 80%;
	}
	.image-upload label {
		cursor: pointer;
	}
	.image-upload input {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}
	.image-upload input :focus + label {
		outline: 1px dotted #000;
		outline: -webkit-focus-ring-color auto 5px;
	}
	.image-upload .upload-icon {
		width: 53px;
	}
	.image-upload .text {
		color: white;
		font-weight: 700;
	}
	.heading .trip-name {
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: 24px;
		text-align: left;
		cursor: pointer;

		/* Input reset */
		border: none;
		display: inline;
		font-family: inherit;
		font-size: inherit;
		padding: none;
		width: auto;
		background-color: transparent;
		color: white;
		box-sizing: border-box;
	}
	.heading input.trip-name {
		width: calc(100% - 30px);
		margin-right: 5px;
		vertical-align: middle;
	}
	/* For changing the edit indicator */
	.heading input.trip-name:focus {
		outline:none;
		/* color: transparent; */
  		/* text-shadow: 0 0 0 white; */
	}
	.heading .save-tick {
		width: 23px;
		margin-top: 2px;
		vertical-align: middle;
	}
	.container {
		background: linear-gradient(0deg, #A4BCC4 0%, #D7DFE1 100%);
	}
	.container .trip-image {
		position: relative;
		background-size: 258px;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url('../../assets/trip/purpose-logo.png');
		/* background-color: blue; */
		height: 251px;
	}
	.container .trip-image .inner {
		background: linear-gradient(180deg, rgba(0, 39, 42, 0) 69%,  rgba(0, 39, 42, 0.9) 100%);
	}
	.trip-image .outer-wrapper {
		min-height: 251px;
	}
	.trip-image .share {
		float: right;
		margin: 25px 25px 0px 0px;
	}
	.trip-image .share:hover {
		cursor: pointer;
	}
	.trip-image .share img {
		width: 24px;
		height: initial;
	}
	.trip-image .trip-label {
		height: auto;
		position: absolute;
		bottom: 0px;
		color: #ffffff;
		font-size: 28px;
		padding: 0px 20px 35px 20px;
	}
	.trip-image td:first-of-type {
		width: 80%;
	}
	.trip-image .trip-label .edit {
		cursor: pointer;
		width: 12px;
		padding-left: 10px;
	}
	.trip-image .heart {
		float: right;
	}
	.trip-image .date {
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 600;
		color: #ffffff;
		margin: 0 auto;
	}
</style>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import helpers from '@/helpers/helpers.js';
export default {
	props: {
	
	},
	data() {
		return {
			editTripHeader: false, // default to false
			unsavedTripName: null,
			savedTripImage: null,
			unsavedTripImage: null,
			unsavedTripImageDisplay: null,
		
			headerIsLoading: false,
			saveHeaderError: "",
		}
	},
	computed: {
		...mapState({
			trip: state => state.trip,
		}),
		headerImage() {
			if(this.unsavedTripImageDisplay) {
				return this.unsavedTripImageDisplay;
			} else if(this.trip.tripImage) {
				return this.trip.tripImage;
			}
		},
		tripDates() {
			let formattedStart = this.formatDateWithoutDay(this.trip.tripDates.start);
			let formattedEnd = this.formatDateWithoutDay(this.trip.tripDates.end);

			if(this.trip.tripDates.start && this.trip.tripDates.end) {
				if(formattedStart == formattedEnd) {
					return formattedStart;
				}
				return formattedStart + " - " + formattedEnd;
			}
			return "";
		},
		
	},
	methods: {
		formatDateWithoutDay: (date) => helpers.formatDateWithoutDay(date),
		...mapActions({
			updateTripHeader: "trip/updateTripHeader",
			alertError: "alert/error",
			alertSuccess: "alert/success",
		}),
		editHeader() {
			this.editTripHeader = true;
			this.$nextTick(() => {
				this.$refs.editNameInput.focus();
			});
		},
		onImageSelected(event) {
			// this.unsavedTripImage = event.target.files[0];
			this.unsavedTripImage = event.target.files.item(0);

			const reader = new FileReader
			reader.onload = e => {
				this.unsavedTripImageDisplay = e.target.result
			}
			reader.readAsDataURL(this.unsavedTripImage)
			this.$refs.addImageText.textContent = this.unsavedTripImage.name;
		},
		saveTripHeader() {
			this.saveHeaderError = "";

			//Validate
			if(this.trip.tripName == null || this.trip.tripName.length == 0) {
				this.saveHeaderError = "Trip name cannot be blank";
				this.alertError(this.saveHeaderError);
				return false;
			}

			if(this.trip.tripName.length > 35) {
				this.saveHeaderError = "Trip name must be under 35 characters";
				this.alertError(this.saveHeaderError);
				return false;
			}

			this.headerIsLoading = true;
			this.updateTripHeader({ tripID: this.trip.tripID, image: this.unsavedTripImage, name: this.trip.tripName })
				.then(response => {
					// this.savedTripName = this.trip.tripname;

					this.unsavedTripImageDisplay = null; // if successful, don't do this (keep as is)
					this.editTripHeader = false;
					this.headerIsLoading = false;

					this.alertSuccess('Saved changes')
				},
				error => {
					this.unsavedTripImageDisplay = null; // Reset displayed image
					this.saveHeaderError = error;
					this.editTripHeader = false;
					this.headerIsLoading = false;
					this.alertError(error);
				});
		},
	},
	created() {	
		
	}
}
</script>